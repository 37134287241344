import { query, request } from '@distancify/drapejs-litium';
import { defineCommand, defineReceiver } from '@drapejs/invoker';
import { gql } from 'graphql-request';

export const commands = {
  ugcMediaSearch: defineCommand<{
    url: string;
  }>('ugcMediaSearch'),
};

export const receivers = {
  ugcMediaSearch: defineReceiver(
    commands.ugcMediaSearch,
    async function (command, data) {
      const result = await request(this.cache,
        query(gql`
        query ugcMediaSearch(
          $url: String!
          $publishedRange: DateIntervalInput
          $skip: Int
          $take: Int
          $articleNumbers: [String]
          $authors: [String]
          $sortBy: UGCMediaSortInput
          $fieldFilters: [FieldFilterInput]
          ) {
          session(url: $url) {
            uGCMediaSearch(
              publishedRange: $publishedRange
              skip: $skip
              take: $take
              articleNumbers: $articleNumbers
              authors: $authors
              sortBy: $sortBy
              fieldFilters: $fieldFilters
            ) {
              uGCMediaDocuments {
                files {
                  systemId
                }
                name
                focalPoint {
                  top
                  left
                }
                post {
                  caption
                  link
                  numberOfComments
                  numberOfLikes
                  timestampUtc
                  username
                }
                products {
                  position {
                    left
                    top
                  }
                  variant {
                    name
                    url
                    priceAndCampaign {
                      unitPrice
                      campaignName
                      campaignPrice
                    }
                  }
                }
              }
            }
          }
        }
      `),
        {
          ...command,
        },
      );

      return {
        result: result.session.uGCMediaSearch,
      };
    },
    'litium',
  ),
};
