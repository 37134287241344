/* eslint-disable import/no-unused-modules */

function performIntegerDivision(a, b) {
  // eslint-disable-next-line no-bitwise
  return ~~(a / b);
}

export function bracketImageRectOnWidth(
  { width, height },
  imageRectBracketStep,
) {
  const ratio = width / height;
  const outWidth = (performIntegerDivision(width, imageRectBracketStep) + 1) * imageRectBracketStep;
  const outHeight = outWidth / ratio;

  return {
    height: Math.round(outHeight),
    width: Math.round(outWidth),
  };
}

export function bracketSquareImage(value, imageBracketStep) {
  const result = (performIntegerDivision(value, imageBracketStep) + 1) * imageBracketStep;
  return {
    height: Math.round(result),
    width: Math.round(result),
  };
}
