import { ref, provide, InjectionKey, reactive, inject, onMounted } from 'vue';
import { routeKey, Route, invokeKey } from '@drapejs/core';
import { commands } from '@/commands/ugcmedia';
export interface UGCMediaBlockOptions {
  setMedia(mediaItems: [any]): void
  numMedia: number
}


export const registerUGCMediaBlockKey: InjectionKey<(uuid: string, options: UGCMediaBlockOptions) => void> = Symbol('registerUGCMediaBlockKey');
export const unregisterUGCMediaBlockKey: InjectionKey<(uuid: string) => void> = Symbol('unregisterUGCMediaBlockKey');

export default function (searchArgs, numberOfMedia) {
  const route = inject(routeKey, <Route>(<any>{}));
  const invoke = inject(invokeKey, () => Promise.resolve())
  
  const product = inject('product', null as any);
  const mediaBlocks = reactive({});
  const ugcMedia = ref([] as any);
  const remainingMedia = ref([] as any);
  
  provide(registerUGCMediaBlockKey, (uuid, options) => {
    mediaBlocks[uuid] = options;
    bindMedia();
  });
  
  provide(unregisterUGCMediaBlockKey, (uuid) => {
    delete mediaBlocks[uuid];
  });
  
  onMounted(async () => {
    const requestArgs = {
      ...searchArgs.value,
      url: `${route.protocol}//${route.host}${route.pathname}`,
    };

    if (numberOfMedia?.value && !requestArgs.hasOwnProperty('take')) {
      requestArgs.take = numberOfMedia.value;
    }

    const currentProductVariants = product?.value?.otherVariants?.map((v) => v.articleNumber) || [];
    if (currentProductVariants.length) {
      requestArgs.articleNumbers = currentProductVariants;
    }


    const response = await invoke(
      commands.ugcMediaSearch,
      requestArgs,
    );

    const docs = response.result.uGCMediaDocuments || [];

    const mappedMedia = docs.map((d) => ({
      focalTop: d.focalPoint.top,
      focalLeft: d.focalPoint.left,
      imageId: d.files[0].systemId,
      products: d.products,
      username: d.post.username,
      caption: d.post.caption,
    }));

    ugcMedia.value = mappedMedia;
    remainingMedia.value = mappedMedia;
    bindMedia();
  });

  function bindMedia() {
    const media = ugcMedia.value;
    if (!media) {
      remainingMedia.value = [];
      return;
    }

    let i = 0;
    for (let uid in mediaBlocks) {
      const block = mediaBlocks[uid];
      block.setMedia(media.slice(i, i + block.numMedia))
      i += block.numMedia;
    }

    remainingMedia.value = media.slice(i);
  }

  return {
    remainingMedia: remainingMedia,
  }
}
